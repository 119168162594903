<template>
  <NuxtLink
    class="text-center"
    :to="link"
    v-bind="
      downloadLinkActive
        ? {
            external: true,
            download: magazine.Name,
            target: '_blank',
            onClick: (e: Event) => handleDownload(e),
          }
        : {}
    "
  >
    <div class="rounded-md overflow-hidden relative">
      <NuxtImg
        v-if="magazine.ImageUrl"
        :src="magazine.ImageUrl"
        :width="370"
        :height="500"
        class="max-w-56 md:max-w-64"
      />
      <div
        v-if="isDownloading"
        class="absolute inset-0 bg-black/30 flex items-center justify-center max-w-56 md:max-w-64"
      >
        <CommonSpinner class="w-8 h-8 text-white" />
      </div>
    </div>
    <div
      class="mt-3"
      :class="true ? 'label-m' : 'list-title-s !font-medium'"
    >
      {{ magazine.Name }}
    </div>
  </NuxtLink>
</template>

<script setup lang="ts">
import type { Magazine } from '~/api/content'

const userStore = useUserStore()
const props = defineProps<{ magazine: Magazine }>()
const isDownloading = ref(false)

const downloadLinkActive = computed(() => {
  return userStore.hasSubscription(1)
})

const link = computed(() => {
  if (true) {
    if (downloadLinkActive.value) {
      return new URL(props.magazine.Link).pathname
    } else {
      return { name: 'login' }
    }
  } else {
    return {
      name: 'magasin_vis',
      params: {
        id: props.magazine.RecordId,
      },
    }
  }
})

const handleDownload = async (event: Event) => {
  if (!downloadLinkActive.value) return

  event.preventDefault()
  isDownloading.value = true

  try {
    const blob = await fetch(new URL(props.magazine.Link).pathname).then((r) =>
      r.blob()
    )
    const url = window.URL.createObjectURL(blob)
    const a = document.createElement('a')
    a.href = url
    a.download = props.magazine.Name
    document.body.appendChild(a)
    a.click()
    window.URL.revokeObjectURL(url)
    document.body.removeChild(a)
  } catch (error) {
    console.error('Download failed:', error)
  } finally {
    isDownloading.value = false
  }
}
</script>
